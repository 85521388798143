body {
  margin: 0;
}
#card {
  font-family: "Segoe UI";
  margin: 5vh auto;
  border-radius: 5px;
  overflow: hidden;
  width: 90%;
  padding: 15px 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

#card .personal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#card .nameHolder h1 {
  font-size: 26px;
  margin: 10px 0 5px;
}

#card .nameHolder h2 {
  margin: 0;
  font-size: 15px;
  color: grey;
}

#card .info {
  padding: 10px 0;
  font-weight: lighter;
  color: #3b3a3a;
  font-size: 18px;
  text-align: center;
}

#card .info span {
  display: block;
  margin: 5px 0;
  border-bottom: 1px solid #cbcbcb;
}

#card .avatar img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 5px solid #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px 0;
}

#card .skills {
  margin: 10px 0;
}

#card .skills span {
  display: inline-block;
  height: 28px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 28px;
  padding: 0 10px;
  border-radius: 14px;
  background-color: #e4e4e4;
  margin: 5px 2px;
}

#card .randomizeButton {
  display: block;
  background-color: #64bc7f;
  text-decoration: none;
  text-align: center;
  margin: 10px 0;
  padding: 10px 0;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

#card .randomizeButton:hover {
  background-color: #57aa70;
}

.animate-change {
  animation-name: slide-in;
  animation-duration: 0.3s;
}

@keyframes slide-in {
  from {
     margin-left: -50px;
     opacity: 0.2;
  }
  to {
     margin-left: 0px;
     opacity: 1;
  }
}

/* Media query for larger screens, say tablets and above */
@media screen and (min-width: 768px) {
  #card {
     width: 450px;
     padding: 15px 25px;
  }

  #card .info {
     padding: 20px 25px;
     font-size: 20px;
  }

  #card .info span {
     display: inline-block;
     margin: 0 15px;
  }

  #card .nameHolder h1 {
     font-size: 30px;
  }

  #card .avatar img {
     width: 180px;
     height: 180px;
  }
}
