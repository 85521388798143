@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .blinking-icon {
    animation: pulse 2s infinite;
  }
  
  @media (min-width: 70px) {
    .blinking-icon {
      font-size: 3rem; /* Adjust size for desktop */
    }
  }
  