.custom-footer {
    background-color: #f7f7f7;
    color: #333;
    padding: 20px 0;
    font-size: 0.875rem;
    border-top: 1px solid #e5e5e5;
}

.custom-footer h5 {
    font-size: 1.125rem;
    margin-bottom: 15px;
    color: #555;
}

.custom-footer p, .custom-footer ul {
    margin-bottom: 10px;
}

.custom-footer ul {
    padding-left: 0;
    list-style-type: none;
}

.custom-footer ul li {
    margin-bottom: 5px;
}

.custom-footer .footer-copyright {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    font-size: 0.8rem;
}

.custom-footer .clearfix {
    display: none;
}

@media screen and (max-width: 768px) {
    .custom-footer .clearfix {
        display: block;
    }
}
